import md5 from "js-md5";
import React from "react";
import { useTranslation } from "react-i18next";

export function InternaDaNorma({ data }) {
  const { t } = useTranslation();
  const getHash = () => {
    const nomeArquivo = `documento_norma_${data.id}.pdf`;

    return md5(nomeArquivo);
  };

  const markup = { __html: data.descricao };

  return (
    <div className="container p-4 ">
      <div
        style={{
          marginBottom: "20px",
        }}
      >
        <h3 className="font-weight-bold">{data.nome}</h3>
        <div className="d-flex align-items-center mt-2">
          <div className="badge badge-primary mr-2">{data.tipo} </div>
          <div className="badge badge-secondary mr-2">
            {data.areaatuacao_nome}
          </div>
          {data.data_conteudo ? (
            <div className="badge badge-light text-dark">
              {data.data_conteudo}
            </div>
          ) : (
            <></>
          )}
        </div>
        {data.resumo && <p className="mt-3">{data.resumo} </p>}
        <hr
          sytle={{
            border: "0",
            height: "1px",
            backgroundColor: "#ccc",
            margin: "20px 0",
          }}
        />

        {data.descricao && (
          <>
            <div
              dangerouslySetInnerHTML={markup}
              className="p-3 bg-white shadow-sm"
              style={{ borderRadius: "1rem" }}
            />
            <hr
              sytle={{
                border: "0",
                height: "1px",
                backgroundColor: "#ccc",
                margin: "20px 0",
              }}
            />
          </>
        )}

        {data.resenha_descricao && (
          <p className="mt-3">{data.resenha_descricao} </p>
        )}
        <a
          className="mr-3 d-flex"
          style={{
            cursor: "pointer",
            color: "#1ab394",
            marginTop: "10px",
          }}
          href={`http://www.taxupdate.co/norma/pdf/${data.id}/${getHash(
            data.id
          )}/?view=1`}
          target="_blank"
          rel="noreferrer"
        >
          {t("interna.clickDownload")}
        </a>
      </div>
      <iframe
        src={`https://www.taxupdate.co/norma/pdf/${data.id}/${getHash(
          data.id
        )}/?view=1`}
        width="100%"
        height="600px"
        title="pdf"
      ></iframe>
    </div>
  );
}
