import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config";
import detalhe from "../img/login/detalhe.png";
import logo from "../img/logo-taxupdate.png";

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email) {
      newErrors.email = "O campo e-mail é obrigatório";
    } else if (!validateEmail(email)) {
      newErrors.email = "O e-mail precisa ser válido";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);
    axios
      .post(`${API_BASE_URL}/contas/reset-password`, { email })
      .then((response) => {
        setSuccess(true);
        setErrors({});
        setLoading(false);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        const newErrors = {};
        if (error.response) {
          const serverErrors = error.response.data;
          newErrors.email =
            serverErrors.message || "Ocorreu um erro ao enviar o e-mail.";
          setErrors(newErrors);
        } else if (error.request) {
          newErrors.email = "Sem resposta do servidor.";
          setErrors(newErrors);
        } else {
          newErrors.email = "Erro ao configurar a requisição.";
          setErrors(newErrors);
        }
      });
  };

  return (
    <div className="">
      <div className="d-flex" style={{ height: "100vh" }}>
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            width: "60%",
            backgroundColor: "#043A5E",
            backgroundImage: `url(${detalhe})`,
            backgroundPosition: "330px center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <img
            src={logo}
            alt="Imagem decorativa"
            style={{
              position: "absolute",
              top: "5%",
              left: "10%",
              width: "124px",
              height: "auto",
            }}
          />
          <div style={{ width: "80%" }}>
            <h2
              className=""
              style={{ fontSize: "38px", fontWeight: "600", color: "#429A85" }}
            >
              Bem Vindo a TaxUpdate!
            </h2>
            <div
              className="text-white"
              style={{
                fontSize: "13px",
                fontWeight: "500",
                lineHeight: "1.5rem",
              }}
            >
              Simplificamos o acompanhamento da legislação tributária do Brasil
              <br />e mais de 30 países da América Latina.
            </div>
            <div
              className="text-white mt-4 mb-3"
              style={{ fontSize: "13px", fontWeight: "500" }}
            >
              Junte-se a empresas inovadoras
            </div>
            {/*
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <img
                  src={samsung}
                  alt="SAMSUNG"
                  style={{ width: "105px", height: "auto" }}
                />
              </div>
              <div className="mr-4">
                <img
                  src={visma}
                  alt="VISMA"
                  style={{ width: "90px", height: "auto" }}
                />
              </div>
              <div className="mr-4">
                <img
                  src={bolt}
                  alt="Bolt"
                  style={{ width: "38px", height: "auto" }}
                />
              </div>
              <div className="mr-4">
                <img
                  src={aws}
                  alt="AWS"
                  style={{ width: "40px", height: "auto" }}
                />
              </div>
              <div className="mr-4">
                <img
                  src={accenture}
                  alt="accenture"
                  style={{ width: "92px", height: "auto" }}
                />
              </div>
              <div className="mr-4">
                <img
                  src={atat}
                  alt="AT&T"
                  style={{ width: "58px", height: "auto" }}
                />
              </div>
            </div>
            */}
          </div>
        </div>

        <div
          className="d-flex flex-column align-items-center justify-content-center bg-white"
          style={{ width: "40%" }}
        >
          <div
            className="custom-normal text-start w-100 pl-5 mb-4"
            style={{ fontSize: "22px", fontWeight: "600" }}
          >
            Recupere sua senha
          </div>
          <form
            className="px-5"
            role="form"
            onSubmit={handleSubmit}
            style={{ width: "100%" }}
          >
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  borderRadius: "7px",
                  borderColor: errors.email
                    ? "#F14437"
                    : success
                    ? "#84BE8C"
                    : "",
                  borderWidth: errors.email ? "2px" : "1px",
                }}
              />
              {errors.email && (
                <small style={{ color: "#F14437" }}>{errors.email}</small>
              )}
              {success && (
                <small style={{ color: "green" }}>
                  O e-mail foi enviado com sucesso
                </small>
              )}
            </div>
            <button
              type="submit"
              className="btn btn-primary block full-width m-b"
              style={{ borderRadius: "7px" }}
              disabled={loading}
            >
              {loading ? "Enviando..." : "Continuar"}
            </button>
            <a
              href="/#"
              className="btn btn-light block full-width"
              role="button"
            >
              Voltar
            </a>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
